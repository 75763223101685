import Image from 'next/image'
import {
  ContactsOutlined,
  MessageOutlined,
  PhoneOutlined
} from '@ant-design/icons'

import * as S from './styles'

import imgLogoSecitecePng from '../../../public/png/logotipo-secitece.png'

export function Footer() {
  return (
    <S.ContainerFooter>
      <S.ColorStripe>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </S.ColorStripe>
      <S.MainFooter>
        <S.ContentFooter>
          <S.WrapperImg>
            <Image
              alt="Logotipo da SECITECE"
              src={imgLogoSecitecePng}
              sizes="100vw"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </S.WrapperImg>

          <S.WrapperInfo>
            <div className="emphasis">
              <span>Sede da Secitece</span>
            </div>
            <div>
              Av. Dr. José Martins Rodrigues, 150 <br /> Edson Queiroz,
              Fortaleza - CE <br />
              CEP: 60.811-520
            </div>
            <div className="emphasis">
              <span>sct.ce.gov.br</span>
            </div>
          </S.WrapperInfo>

          <S.WrapperNav>
            <ul>
              <li>
                <a
                  href="https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria?locale=pt-BR"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <MessageOutlined />
                    </div>
                    Ouvidoria
                  </div>
                </a>
              </li>

              <li>
                <a href="tel:+155" target="_blank" rel="noreferrer">
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <PhoneOutlined />
                    </div>
                    Central 155
                  </div>
                </a>
              </li>

              <li>
                <a href="#">
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <ContactsOutlined />
                    </div>
                    Horário de Atendimento <br />
                    08 às 17 horas
                  </div>
                </a>
              </li>
            </ul>
          </S.WrapperNav>
        </S.ContentFooter>
      </S.MainFooter>
      <S.BottomFooter>
        <span>
          ©2024 Governo do Estado do Ceará - Todos os direitos reservados.
        </span>
      </S.BottomFooter>
    </S.ContainerFooter>
  )
}
