import styled from 'styled-components'

export const ContainerPublicLayout = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  color: ${({ theme }) => theme.colors.black};

  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.white} 100px, ${theme.colors.bg} 300px,  ${theme.colors.bg} 100%)`};

  overflow: hidden;
`

export const ContentPublicLayout = styled.div`
  flex: 1;

  width: 100%;
`
