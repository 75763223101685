import styled from 'styled-components'

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${({ theme }) => theme.colors.white};
`

export const ColorStripe = styled.div`
  display: flex;
  width: 100%;
  height: 15px;

  div {
    flex: 1;
    height: 100%;
  }

  div:nth-child(1) {
    background-color: #ffe86f;
  }
  div:nth-child(2) {
    background-color: #ffde32;
  }
  div:nth-child(3) {
    background-color: #ffca16;
  }
  div:nth-child(4) {
    background-color: #55900e;
  }
  div:nth-child(5) {
    background-color: #55a546;
  }
  div:nth-child(6) {
    background-color: #87c878;
  }
`

export const MainFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: ${({ theme }) => `${theme.spacings.large}`};

  background-color: ${({ theme }) => theme.colors.tertiary};
`

export const BottomFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  background-color: #ebefec;
  color: ${({ theme }) => theme.colors.black};

  > span {
    padding: ${({ theme }) =>
      `${theme.spacings.xsmall} ${theme.spacings.large}`};
    font-size: ${({ theme }) => theme.font.sizes.medium};
    text-align: center;
  }
`

export const ContentFooter = styled.div`
  width: 100%;
  max-width: 1140px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacings.large};

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`

export const WrapperImg = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacings.small};

  img {
    max-width: 270px;
  }
`

export const WrapperInfo = styled.div`
  width: 100%;

  font-size: ${({ theme }) => theme.font.sizes.medium};
  line-height: ${({ theme }) => theme.font.sizes.xlarge};

  > div.emphasis {
    font-weight: ${({ theme }) => theme.font.bold};
  }

  > div:last-of-type {
    margin-top: ${({ theme }) => theme.spacings.xsmall};
  }

  > div:first-of-type {
    margin-bottom: ${({ theme }) => theme.spacings.xxsmall};
  }
`

export const WrapperNav = styled.div`
  width: 100%;

  font-size: ${({ theme }) => theme.font.sizes.medium};
  line-height: ${({ theme }) => theme.font.sizes.xlarge};

  > div.title {
    font-weight: ${({ theme }) => theme.font.normal};
    margin-bottom: ${({ theme }) => theme.spacings.xsmall};
  }

  > ul {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
  }

  div.wrapper-icon-footer {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    > .icon-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: ${({ theme }) => theme.font.sizes.medium};

      background-color: transparent;
      width: 2.2rem;
      height: 2.2rem;
      padding: 0.2rem;
    }

    color: ${({ theme }) => theme.colors.white};

    transition: color 200ms ease-in-out;

    &.link:hover {
      color: ${({ theme }) => theme.colors.gray};
    }
  }
`
